
import PortfolioIdleTenancy from "~/graphql/Portfolio/PortfolioIdleTenancy.gql";
import PortfolioTenancyLease from "~/graphql/Portfolio/PortfolioTenancyLease.gql";

import GraphMenu from "../../components/GraphMenu/GraphMenu.vue";
import { GraphInputBuilder } from "~/helpers/apollo/apollo-portfolio-helpers.js";
import { getPortfolioColors } from "~/helpers/portfolio/portfolio-helpers.js";
import { formatIdleTenancyRate, formatEvictionRate } from "~/helpers/portfolio/portfolio-graph-helpers";
import { filter } from "~/composables/useFilter";

const preferenceNameSpacePortfolio = "preferenceNamespacePortfolioPortfolio";

export default {
  components: {
    GraphMenu,
  },

  props: {
    portfolioId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      idleTenancyRateMenuOpen: false,
      idleTenancyRateFilterDateFrom: undefined,
      evictionRateMenuOpen: false,
      needTenantRateMenuOpen: false,
      idleTenancyRateFilterDateTo: undefined,
      idleTenancyRateFilterPeriodType: undefined,
      evictionRateFilterDateFrom: undefined,
      evictionRateFilterDateTo: undefined,
      evictionRateFilterPeriodType: undefined,
      colors: getPortfolioColors(),
    };
  },

  apollo: {
    idleTenancy: {
      query: PortfolioIdleTenancy,
      variables: function () {
        return new GraphInputBuilder()
          .setPortfolioId(this.portfolioId)
          .setDateFrom(this.idleTenancyRateFilterDateFrom)
          .setDateTo(this.idleTenancyRateFilterDateTo)
          .setPeriodType(this.idleTenancyRateFilterPeriodType)
          .build();
      },
      update(data) {
        return data.idleTenancyMetricsForPortfolio;
      },
      skip: function () {
        return !this.idleTenancyRateFilterDateFrom || !this.idleTenancyRateFilterDateTo || !this.idleTenancyRateFilterPeriodType;
      },
    },

    eviction: {
      query: PortfolioTenancyLease,
      variables: function () {
        return new GraphInputBuilder()
          .setPortfolioId(this.portfolioId)
          .setDateFrom(this.evictionRateFilterDateFrom)
          .setDateTo(this.evictionRateFilterDateTo)
          .setPeriodType(this.evictionRateFilterPeriodType)
          .build();
      },
      update(data) {
        return data.tenancyLeaseOverviewForPortfolio;
      },
      skip: function () {
        return !this.evictionRateFilterDateFrom || !this.evictionRateFilterDateTo || !this.evictionRateFilterPeriodType;
      },
    },
  },

  computed: {
    filter() {
      return filter;
    },

    idleTenancyLoading() {
      return this.$apollo.queries.idleTenancy.loading;
    },

    evictionLoading() {
      return this.$apollo.queries.eviction.loading;
    },

    idleTenencyRate() {
      if (!this.idleTenancy) return null;

      return formatIdleTenancyRate(this.idleTenancy, this.idleTenancyRateFilterPeriodType);
    },

    evictionRate() {
      if (!this.eviction) return null;

      return formatEvictionRate(this.eviction, this.evictionRateFilterPeriodType);
    },
  },

  watch: {
    idleTenancyRateFilterDateTo(val) {
      this.$userPreferences.setKey(preferenceNameSpacePortfolio, "idleTenancyRateFilterDateTo", val);
    },

    idleTenancyRateFilterDateFrom(val) {
      this.$userPreferences.setKey(preferenceNameSpacePortfolio, "idleTenancyRateFilterDateFrom", val);
    },

    idleTenancyRateFilterPeriodType(val) {
      this.$userPreferences.setKey(preferenceNameSpacePortfolio, "idleTenancyRateFilterPeriodType", val);
    },
  },

  async mounted() {
    // Fetch user preferences
    this.idleTenancyRateFilterDateTo = await this.$userPreferences.getKey(preferenceNameSpacePortfolio, "idleTenancyRateFilterDateTo");
    this.idleTenancyRateFilterDateFrom = await this.$userPreferences.getKey(preferenceNameSpacePortfolio, "idleTenancyRateFilterDateFrom");
    this.idleTenancyRateFilterPeriodType = await this.$userPreferences.getKey(preferenceNameSpacePortfolio, "idleTenancyRateFilterPeriodType");

    this.evictionRateFilterDateTo = await this.$userPreferences.getKey(preferenceNameSpacePortfolio, "evictionRateFilterDateTo");
    this.evictionRateFilterDateFrom = await this.$userPreferences.getKey(preferenceNameSpacePortfolio, "evictionRateFilterDateFrom");
    this.evictionRateFilterPeriodType = await this.$userPreferences.getKey(preferenceNameSpacePortfolio, "evictionRateFilterPeriodType");
  },

  methods: {
    toggleIdleTenancyRateMenu() {
      this.idleTenancyRateMenuOpen = !this.idleTenancyRateMenuOpen;
    },

    updateIdleTenancyRateFilter(event) {
      this.idleTenancyRateFilterDateFrom = event.dateFrom;
      this.idleTenancyRateFilterDateTo = event.dateTo;
      this.idleTenancyRateFilterPeriodType = event.periodType;
    },

    updateEvictionRateFilter(event) {
      this.evictionRateFilterDateFrom = event.dateFrom;
      this.evictionRateFilterDateTo = event.dateTo;
      this.evictionRateFilterPeriodType = event.periodType;
    },

    toggleEvictionRateMenu() {
      this.evictionRateMenuOpen = !this.evictionRateMenuOpen;
    },
  },
};
